import React, { PureComponent } from 'react';

import { 
  InfoArea,
  InfoHeader,
  InfoContent } from '../atomicStyledComponents/stylesIndex';

class InfoBox extends PureComponent {
  constructor({ data }) {
    const { config , title, text } = data;
    super();
    this.state = { loaded: false };
    this.title = title;
    this.text = text;
    this.config = config;
  }

  static getDerivedStateFromProps(nextProps) {
    if (nextProps.loaded) {
      return { loaded: nextProps.loaded }};
    return null;
  }

  insertMarkup = _ => {
    return { __html: this.text }
  }

  render() {
    return (
      <InfoArea {...this.config}>
        <InfoHeader>
          { this.title }
        </InfoHeader>
        <InfoContent dangerouslySetInnerHTML={ this.insertMarkup() } />
      </InfoArea> )
  }
}

export default InfoBox;
